<template>
  <div class="column is-12">
    <div class="box has-padding-100">
      <apart>
        <p>
          <b-icon
            v-if="site.quota.planTaskCredits"
            icon="thumbs-up"
            pack="far"
            type="is-dark"
          />
          <span v-if="site.quota.planTaskCredits">
            <span v-if="hasCredit">
              Good news –
            </span>
            {{ site.fqdn }} has
            <strong>
              {{
                site.quota.planTaskCredits > 500
                  ? `unlimited`
                  : `${site.quota.planTaskCredits} available`
              }}
            </strong>
            task credits.
          </span>
          <span v-else>
            <span v-if="hasCredit">
              Good news –
            </span>
            {{ site.fqdn }} has
            <strong>{{
              site.quota.paidTaskCredits
                ? `${site.quota.paidTaskCredits} unused`
                : `no available`
            }}</strong>
            task credits.
          </span>
        </p>
        <button
          class="button is-dark is-rounded is-outlined"
          @click="$emit('createTask')"
        >
          Create task
        </button>
      </apart>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    hasCredit() {
      return (
        this.site &&
        this.site.quota &&
        (this.site.quota.planTaskCredits || this.site.quota.paidTaskCredits)
      );
    }
  }
};
</script>
